import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Moving from "./pages/Moving";
import { ThemeProvider, BaseStyles, Box } from "@primer/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <Box sx={{backgroundColor: 'canvas.subtle'}}>
   <Moving />
 </Box>

    

    
);


